<template>
  <div class="container pad-tb10">
    <el-row :gutter="10">
      <el-col :span="18">
        <div class="pad-tb15 bg-white">
          <h2 style="border-left: 5px solid #3153d4; padding-left: 10px;">意见反馈</h2>
        </div>
        <div class="bg-white pad15">
          <el-form ref="feedBackForm" :model="feedBackForm" :rules="feedBackFormRules"
                   label-width="150px">
            <el-form-item label="反馈类型" prop="typeId">
              <el-radio v-model="feedBackForm.typeId"
                        :key="type.typeId"
                        v-for="type in feedbackTypeList"
                        :label="type.typeId"
              >{{ type.typeName }}
              </el-radio>
            </el-form-item>
            <el-form-item label="反馈内容" prop="content">
              <el-input type="textarea" :rows="5" maxlength="255" placeholder="反馈内容"
                        v-model="feedBackForm.content"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="contactWay">
              <el-input v-model="feedBackForm.contactWay" placeholder="电话/邮箱/微信/QQ"></el-input>
            </el-form-item>
            <el-form-item label="图片上传">
              <el-upload
                :http-request="fileUploadFunction"
                :data="{...uploadConfig.uploadEnum.FEEDBACK}"
                :class="{hiddenUpload: feedBackForm.images && feedBackForm.images.length >= 4}"
                action=""
                list-type="picture-card"
                :multiple="true"
                :limit="4"
                :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'images'})"
                :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'images'})"
                :on-remove="uploadConfig.onRemove.bind(null,  {key: 'images'})"
                :on-exceed="uploadConfig.fileExceed"
                :on-error="uploadConfig.uploadFileError"
                ref="uploadfiles"
                :file-list="feedBackForm.images"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="onSubmitClick('feedBackForm')">提交反馈
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="6"></el-col>
    </el-row>
  </div>
</template>

<script>
import uploadEnum from "@/resources/js/upload-enum";
import {submitFeedback} from "@/apis/feedback";

export default {
  components: {},
  data() {
    return {
      feedbackTypeList: [
        {typeId: 1, typeName: '功能异常'},
        {typeId: 2, typeName: '体验问题'},
        {typeId: 3, typeName: '新功能建议'},
        {typeId: 4, typeName: '其他'}
      ],
      feedBackForm: {
        typeId: null,
        content: null,
        contactWay: null,
        userId: null,
        images: []
      },
      feedBackFormRules: {
        typeId: [
          {required: true, message: '反馈类型非法', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '需求描述不能为空', trigger: 'blur'}
        ],
        contactWay: [
          {required: true, message: '联系方式不能为空', trigger: 'blur'}
        ]
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
          if (key === 'images') {
            this.feedBackForm[key] = fileList;
          }
          this.$message.success('文件上传成功！');
        },
        onRemove: ({key}, file, fileList) => {
          if (key === 'images') {
            this.feedBackForm[key] = fileList;
          }
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          if (key === 'images') {
            const isImage = file.type.startsWith('image')
            const isLtSize = file.size / 1024 / 1024 < .5
            if (!isImage) {
              this.$message.error('只能上传 图片 格式!')
            }
            if (!isLtSize) {
              this.$message.error('文件大小不能超过512KB!')
            }
            result = isImage && isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      },
    }
  },
  methods: {
    onSubmitClick(formName) {
      console.log(this.feedBackForm.images.length)
      this.$refs[formName].validate()
        .then(() => {
          let form = this.feedBackForm;
          submitFeedback({
            userId: (this.user && this.user.userId) || null,
            images: (form.images || []).map(item => item.response.src).join(','),
            contact: form.contactWay,
            content: form.content,
            typeId: form.typeId,
          }).then(res => {
            this.$message.success('反馈提交成功！');
            this.$refs[formName].resetFields();
            this.$refs.uploadfiles.clearFiles();
            console.log(this.feedBackForm.images.length)


          })
        });

    }
  },
  beforeMount() {
  }
}
</script>

<style scoped lang="scss">
</style>
