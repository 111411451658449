/**
 * 用户反馈js
 */
import axios from "@/resources/js/request";
const request = axios.request;
/**
 * 发送手机验证码
 * @param phone
 */
export const submitFeedback = ({userId, typeId, content, images, contact}) => {
  return request.doPost('/common/submitFeedback', {
    userid: userId,
    typeid: typeId,
    content: content,
    contactway: contact,
    imgurls: images,
  });
}
